import { Link } from 'gatsby';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Container from '../Container';

const sharedStyledLink = css`
  margin-bottom: 1rem;
  margin-right: 2rem;
  text-transform: uppercase;
  color: #333;
  transition: color 0.2s linear;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media (min-width: 426px) {
    margin-bottom: 0;
  }
`;

const StyledNav = styled.nav`
  padding: 1rem 0;
`;

const StyledContaienr = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 426px) {
    flex-direction: row;
  }
`;

const StyledAnchor = styled.a`
  ${sharedStyledLink}
`;
const NavItem = styled(Link)`
  ${sharedStyledLink}
`;

const Nav = () => {
  return (
    <StyledNav>
      <StyledContaienr>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/blog">Blog</NavItem>
        <StyledAnchor href="/book" target="_blank" rel="noreferrer">
          Book Appointment
        </StyledAnchor>
      </StyledContaienr>
    </StyledNav>
  );
};

export default Nav;
