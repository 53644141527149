import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Meta = {
  name?: string;
  property?: string;
  content: string;
};

type Props = {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title?: string;
  image?: string;
  children?: React.ReactNode;
  uri: string;
};

const SEO = ({
  description = '',
  lang = 'en',
  meta = [],
  title = '',
  image = null,
  children,
  uri,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image ? image : '/images/og-image.png',
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={`https://barefacedbyelle.co.uk/${uri}`} />
      {children}
    </Helmet>
  );
};

export default SEO;
