import * as React from 'react';
import styled from 'styled-components';

import TikTokIcon from '../../images/tik-tok.inline.svg';
import InstagramIcon from '../../images/instagram.inline.svg';
import Container from '../Container';

const StyledFooter = styled.footer`
  padding: 2rem 1rem;
  background-color: #f4f3ef;
`;

const StyledContainer = styled(Container)`
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 426px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Copyright = styled.span`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  text-align: center;

  small {
    margin-top: 0.5rem;
  }
`;

const SocialList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SocialListItem = styled.li`
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledH4 = styled.h4`
  margin: 0 0 1rem 0;
`;

const StyledAddress = styled.address`
  font-style: normal;
  line-height: 1.5rem;
`;

const StyledOL = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledLi = styled.li`
  margin-bottom: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    margin-right: 1.5rem;
    text-transform: uppercase;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledContainer>
        <div>
          <StyledH4>Opening Hours</StyledH4>
          <StyledOL>
            <StyledLi>
              <strong>Mon</strong> Closed
            </StyledLi>
            <StyledLi>
              <strong>Tue</strong> 09:30 - 15:30
            </StyledLi>
            <StyledLi>
              <strong>Wed</strong> 11:00 - 19:00
            </StyledLi>
            <StyledLi>
              <strong>Thu</strong> 11:00 - 19:00
            </StyledLi>
            <StyledLi>
              <strong>Fri</strong> 09:30 - 15:30
            </StyledLi>
            <StyledLi>
              <strong>Sat</strong> Closed
            </StyledLi>
            <StyledLi>
              <strong>Sun</strong> Closed
            </StyledLi>
          </StyledOL>
        </div>
        <div>
          <StyledH4>Where to Find Us</StyledH4>
          <StyledAddress>
            Office 15
            <br />
            Acklam Hall
            <br />
            Hall Drive, Acklam
            <br />
            Middlesbrough, TS5 7DY
          </StyledAddress>
        </div>
        <div>
          <StyledH4>Social</StyledH4>
          <SocialList>
            <SocialListItem>
              <a
                href="https://www.instagram.com/barefacedbyelle/"
                target="_blank"
                rel="noopener nofollow"
                aria-label="Instagram"
              >
                <InstagramIcon width="24px" height="24px" />
              </a>
            </SocialListItem>
            <SocialListItem>
              <a
                href="https://www.tiktok.com/@barefacedbyelle"
                target="_blank"
                rel="noopener nofollow"
                aria-label="TikTok"
              >
                <TikTokIcon width="24px" height="24px" />
              </a>
            </SocialListItem>
          </SocialList>
        </div>
      </StyledContainer>
      <Copyright>
        &copy; {new Date().getFullYear()} Bare Faced BY Elle Limited
        <small>Company No 13619494</small>
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
