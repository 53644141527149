import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
`;

export default Container;
