import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import Container from '../Container';

const StyledHeader = styled.header`
  padding: 2rem 1rem;
  background-color: #f4f3ef;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
`;

const StyledH1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  color: #333333;
  text-align: center;
  letter-spacing: 0.5px;
`;

const Header = () => {
  return (
    <StyledHeader>
      <StyledContainer justifyContent="space-between" alignItems="center">
        <Link to="/">
          <StyledH1>Bare Faced by Elle</StyledH1>
        </Link>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
