import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import styled from 'styled-components';

import MerriweatherBoldWoff from '../../fonts/Merriweather-Bold.woff';
import MerriweatherBoldWoff2 from '../../fonts/Merriweather-Bold.woff2';
import MerriweatherWoff from '../../fonts/Merriweather-Regular.woff';
import MerriweatherWoff2 from '../../fonts/Merriweather-Regular.woff2';
import MerriweatherItalicWoff from '../../fonts/Merriweather-Italic.woff';
import MerriweatherItalicWoff2 from '../../fonts/Merriweather-Italic.woff2';

import Container from '../Container';
import Header from '../Header';
import Footer from '../Footer';
import SEO from '../Seo';
import Nav from '../Nav';

const StyledContainer = styled(Container)`
  padding: 2rem 0;
`;

const StyledMain = styled.main`
  padding: 0 1rem;
  flex: 1;
`;

type Props = {
  children: React.ReactNode;
};

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather'),
    url(${MerriweatherWoff2}) format('woff2'),
    url(${MerriweatherWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather'),
    url(${MerriweatherItalicWoff2}) format('woff2'),
    url(${MerriweatherItalicWoff}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather'),
    url(${MerriweatherBoldWoff2}) format('woff2'),
    url(${MerriweatherBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0px;
    font-family: 'Merriweather', serif;
    color: #444444;
  }

  a {
    color: #444444;
    font-weight: 700;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
`;

const Layout = ({ children }: Props) => {
  return (
    <>
      <GlobalStyle />
      <SEO />
      <Nav />
      <Header />
      <StyledMain>
        <StyledContainer>{children}</StyledContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Layout;
